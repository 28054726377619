
// Dobrej tip: https://www.sitepoint.com/effective-event-binding-jquery/

var $j3wApp = $j3wApp || {};

(function ($) {

    'use strict';
    
    if (!$.j3wThemeExtensions)
        $.j3wThemeExtensions = {};
    
    function J3WTheme() {

        var self = this;
        
        self.$window = $(window);
        self.$document = $(document);
        self.$body = $("body");
        
        self.$formValidate = $(".form-validate");

        self.$popup = $(".j3w-popup");
        self.$popupClose = $(".j3w-popup-close");
        self.$popupBg = $(".j3w-popup-bg");
        self.$popupContent = $(".j3w-popup-content");
        // To ID je definovano v php pro xajax
        self.$popupContentInner = $("#popup_inner_container");
        self.$openPopupBtn = $(".open-popup");
        self.$firePopup = $(".fire-popup");

        self.$j3wSelect = $(".j3w-select");
        self.$j3wSelectHeader = $(".j3w-select-header");
        self.$j3wOption = $(".j3w-option");
        
        self.j3wSelect = ".j3w-select";
        self.j3wSelectHeader = ".j3w-select-header";
        self.j3wSelectDropdown = ".j3w-select-dropdown";
        self.j3wOption = ".j3w-option";
        self.j3wOptionSelected = ".j3w-option-selected";
        
        self.j3wOptionSelectedStr = "j3w-option-selected";
        
        self.$dwlSelect1 = $("#j3w-select-1");
        self.$dwlSelect2 = $("#j3w-select-2");
        
        self.$j3wFormCheck = $(".j3w-form-check");

        self.deliveryAddress = $("#delivery-address");
        self.showDeliveryAddress = $("#show-delivery-address");
        
        self.$serviceForm = $("#service-form");
        self.$agreeTerms = $("#agree-terms");
        self.$serviceFormSubmitBtn = $("#service-form-submit-btn");
        
        self.$showPIN = $(".pin-show");
        self.$popupPINtpl = $("#popup-pin-tpl");
        self.$copyPIN = $("#copy-pin");
        self.$copyPINquick = $(".copy-pin-quick");

        self.$popupUserTpl = $("#popup-user-tpl");
        self.$yndYes = $("#ynd-yes");
        self.$yndNo = $("#ynd-no");

        setTimeout( function(){
            $(".login-form-group-password").addClass("show");
        }, 600 );
        
        // Initialize scripts
        self._init();

    }
    
    J3WTheme.prototype = {
        
        _init: function () {
            
            var self = this;

            self.onBind();
            self.formValidate();
            self.serviceFormValidate();
            
        },
        /*
        loadExtension: function() {
            
            var self = this;
                        
        },
        */
        onBind: function() {
            
            var self = this;
            
            // -----------------------------------------------------------------
            // CUSTOM SELECT
            
            // Otevirame a zavirame select
            self.$body.on("click", self.j3wSelectHeader, function(){
                // Reset vsechny selecty a aktualni taky
                if( $(this).parent().hasClass("open") ){
                    self.selectClose();
                // Jinak reset vsechny a ten nas otevrit
                } else {
                    self.selectClose();
                    $(this).parent().addClass("open");
                }
            });
            
            // Zavrit custom select dropdown kdyz se klepne mimo
            $(document).on( "click", function (e) {
                if ( !self.$body.find( self.j3wSelect ).is( e.target ) && self.$body.find( self.j3wSelect ).has( e.target ).length === 0 ) {
                    self.selectClose();
                }
            });
            
            // Vybirame z option v download
            self.$body.on( "click", self.j3wOption, function() {
                
                if( $("#j3w-select-row-download").length ){
                    self.optionDownloadSelected( $(this) );
                }
                
                if( $("#service-form").length ){
                    self.optionServiceSelected( $(this) );
                }
                
            });

            // -----------------------------------------------------------------
            // PIN - copy
            
            // Kopírování pinu do schránky
            self.$body.on("click", "#copy-pin", function(){
                self.copyPINtoClipboard( $(this) ); 
            });
            
            // Kopírování pinu do schránky bez popup okna
            self.$copyPINquick.on("click", function(){
               self.copyPINtoClipboardQuick( $(this) );
            });
            
            // -----------------------------------------------------------------
            // POPUP
            
            // Otevirame okno
            self.$firePopup.on("click", function(e) {
                // Otevreme okno
                // Content je skryty
                self.popupShow();
                
                // get data
                var type = $(this).data("type"),
                    id = $(this).data("id");
                    
                // is undefined?
                type = ( typeof type === 'undefined' || type === null ) ? 'error' : type;
                id = ( typeof type === 'undefined' || id === null ) ? 0 : id;
                
                // Zavolame ajax a prepiseme HTML
                xajax_setPopUpContent( type, id );
                
                // Kdyby by slo o odkaz - stopnem ho
                e.preventDefault();
            });
            
            // Zavirani popup ruznymi zpusoby
            // Tlacitko s krizkem
            self.$popupClose.on("click", function () {
                self.popupClose();
            });
            
            // Kdyz se klepne na background 
            self.$popupBg.on("click", function () {
                self.popupClose();
            });
            
            // Kdyz klepne na ESC
            self.$document.keydown(function (e) {
                if (e.cancelable === true && e.key === 'Esc' || e.key === 'Escape' || e.keyCode === 27) {
                    self.popupClose();
                }
            });
            
            // Mazat NE - zavřít popup
            self.$body.on("click", "#ynd-no", function(){
                self.popupClose();
            });
            
            // Mazat ANO
            self.$body.on("click", "#ynd-yes", function(){
                document.user_id = $(this).data("id");
                xajax_deleteUser( document.user_id );
            });
            
            // -----------------------------------------------------------------
            // SERVICE form - zobrazeni dodaci adresy

            self.showDeliveryAddress.on("click", function(){
                $(this).find(".j3w-checkbox").toggleClass("checked");
                self.deliveryAddress.fadeToggle(200);
            });

            $("#agree-regist-terms, #agree-regist-newsletter").on("click", function(){
                $(this).find(".j3w-checkbox").toggleClass("checked");
                var $chbox = $(this).find('input[type="checkbox"]');
                $chbox.prop( "checked", !$chbox.prop("checked") );
            });
            
            self.$agreeTerms.on("click", function(){
                
                // Najdu checkbox
                var $checkbox = $(this).find(".j3w-checkbox");
                
                // Nastavime checked
                $checkbox.toggleClass("checked");
                
                // Podle toho jestli je zaskrtnuto rozhodnu co s tim
                // Bud bude button submit nebo ne a bud se formular posle nebo ne
                /*
                if( $checkbox.hasClass("checked") ){
                    self.$serviceFormSubmitBtn.attr("type", "submit");
                } else {
                    self.$serviceFormSubmitBtn.attr("type", "button");
                }
                */
            });
            
            // -----------------------------------------------------------------
            // USER form - nastavovani prav uzivatele
            
            $("#user-form").on("click", ".j3w-form-check", function(){
                
                var $checkbox = $(this).find(".j3w-checkbox");
               
                $checkbox.toggleClass("checked"); 
               
                var id = $checkbox.data("id");
               
               if( $checkbox.hasClass("checked") ){
                   $("#" + id).val(1);
               } else {
                   $("#" + id).val(0);
               }

            });

        },
        
        removeUnread: function( id ){
            
            $("#" + id).removeClass("unread");
            
        },
        
        showPassword: function(){
            
            setTimeout(function(){
                $("#login-form-group-password").addClass("show");
            }, 100);

        },
        
        /* Tahle funkce se vola, po tom, co xajax prepise HTML */
        
        popupOpenAfter: function() {
            
            var self = this;
            
            self.popupContentShow();
            
        },
        
        popupCloseAfter: function(){
            
            var self = this;
            
            self.popupClose();
            
            // Po zavreni okna mazeme radku u useru
            $("#user_row_" + document.user_id).fadeOut( 300, function(){ $(this).remove(); } );
            
        },
        
        /* Default je velky okno - size = j3w-popup-small bude maly okno */
        
        popupContentShow: function() {
            
            var self = this;
            
            setTimeout(function () {
                
                self.$popup.removeClass("loading");
                self.$popupContentInner.addClass("show");
                
            }, 200 );
  
        },
        
        popupShow: function( size ) {
        
            var self = this;
            
            if (typeof size === 'undefined') {
                size = '';
            } else {
                size = " " + size;
            }
            
            self.$popup.addClass("show loading" + size);
            
            setTimeout(function () {
 
                self.$popupContent.addClass("show");

            }, 300 );
            
        },
        
        popupOpen: function ( $obj, size ){
            
            var self = this;
            
            self.popupShow( size );
            
            setTimeout(function () {
                
                self.$popupContentInner.html( $obj.html() );
                self.$popupContent.addClass("show");

            }, 200 );
            
        },
        
        popupClose: function () {

            var self = this;

            self.$popupContent.removeClass("show");
            
            setTimeout(function () {
                
                self.$popup.removeClass("show j3w-popup-small"); // smazeme pripadne tridu pro maly okno
                self.$popupContentInner.empty().removeClass("show");
                self.$popupContent.removeAttr("id");
                    
            }, 200 );

        },
        
        popupOpenPin: function( html ) {
        
            var self = this;
            
            self.popupShow();
            
            setTimeout(function () {
                
                self.$popupContentInner.html( html );
                self.$popupContent.addClass("show");

            }, 200 );
            
        },
        
        copyPINtoClipboard: function ( $this ) {
            
            var self = this;
            
            if( $("#copy-pin-status").hasClass("show") ){
                return false;
            }
            
            var pin = $this.data("pin");

            self.copyToClipboard( pin );
            
            var $status = $("#copy-pin-status");

            $status.text( $status.data("text") ).addClass("show");
            
            setTimeout( function(){
               $status.removeClass("show"); 
            }, 2000);

        },
        
        copyPINtoClipboardQuick: function( $this ){

            var self = this;
            
            if( $this.hasClass("copy-start") ){
                return false;
            }
            
            self.$copyPINquick.removeClass("copy-end copy-start");
            
            var pin = $this.data("pin");
            
            self.copyToClipboard( pin );
            
            $this.addClass("copy-start");
            setTimeout( function(){
                $this.addClass("copy-end");
            }, 300);
            
        },
        
        copyToClipboard: function( str ){
            
            var self = this;
            
            var $temp = $("<input>");
            self.$body.append( $temp );
            $temp.val( str ).select();
            document.execCommand("copy");
            $temp.remove();
            
        },
        
        optionServiceSelected: function( $this ){
            
            // $this je option v tom custom selectu
          
            var self = this;
            
            var $select = $this.closest(".j3w-select");

            // Nastavime hodnotu v tom option
            $select.find(".j3w-select-header").text( $this.text() );
            
            // Smazeme zvyrazneni vybraneho selectu
            $this.closest( self.j3wSelectDropdown ).find( self.j3wOption ).removeClass( self.j3wOptionSelectedStr );
            
            // Nastavime zvyrazneni pro aktualni option
            $this.addClass( self.j3wOptionSelectedStr );
            
            // Zavreme select
            self.selectClose();

            // Vytahneme ID hidden pole ktere budeme menit
            var id = $select.data("id");

            // Zmenime hodnotu hidden pole
            $("#" + id ).val( $this.text() );
 
        },
        
        optionDownloadSelected: function( $this ) {
            
            var self = this;
            
            var ID1, ID2;
            
            var dwlselect1 = "dwl-select-1",
                dwlselect2 = "dwl-select-2";
            
            // Reset selected
            $this.closest( self.j3wSelectDropdown ).find( self.j3wOption ).removeClass( self.j3wOptionSelectedStr );
            
            // Reset druhy select - aby nezustal vybrany zadny produkt
            if( $this.closest(".j3w-select").attr("id") == dwlselect1 ){
                $("#"+dwlselect2).find( self.j3wOption ).removeClass( self.j3wOptionSelectedStr );
            }
            
            // Add selected
            $this.addClass( self.j3wOptionSelectedStr );
            
            // Close all
            self.selectClose();
            
            // Hide old result
            $("#download-list").fadeOut(200);
            
            // Get data
            $(self.j3wSelect).each( function() {
                
                if( $(this).attr("id") == dwlselect1 ){
                    ID1 = $(this).find( self.j3wOptionSelected ).data("id");
                    ID1 = ( ID1 ) ? ID1 : 0;
                }
                if( $(this).attr("id") == dwlselect2 ){
                    ID2 = $(this).find( self.j3wOptionSelected ).data("id");
                    ID2 = ( ID2 ) ? ID2 : 0; 
                }
            });
            
            self.ajaxDownloadCall( ID1, ID2 );
            
        },
        
        ajaxDownloadCall: function( ID1, ID2 ) {
            
            xajax_setDownloadContent( ID1, ID2 );
            
        },
        
        selectClose: function(){
            
            var self = this;
            
            self.$body.find( self.j3wSelect ).removeClass("open");
            
        },
        
        serviceFormValidate: function() {
            
            var self = this;
            
            self.$serviceForm.validate({
                
                errorElement: "em",
                
                submitHandler: function ( form ) {
                    
                    var $terms = self.$agreeTerms.find(".j3w-checkbox"),
                        $doprava = $("#select-doprava"),
                        $platba = $("#select-platba");
                
                    var valid = 0;
                    
                    if( $(form).attr("id") === "service-form" && $terms.hasClass("checked") ){
                        self.$agreeTerms.removeClass("error");
                    } else {
                        valid++;
                        self.$agreeTerms.addClass("error");
                    }
                    
                    if( $doprava.find(".j3w-select-header").text() != "Vyberte způsob dopravy" ){
                        $doprava.removeClass("error");
                    } else {
                        valid++;
                        $doprava.addClass("error");
                    }
                    
                    if( $platba.find(".j3w-select-header").text() != "Vyberte způsob platby" ){
                        $platba.removeClass("error");
                    } else {
                        valid++;
                        $platba.addClass("error");
                    }
                    
                    if( valid == 0 ){
                        form.submit();
                    }
                    
                }
                
            });
            
        },
        
        formValidate: function() {
            
            var self = this;
            
            self.$formValidate.validate({

                errorElement: "em",

                submitHandler: function ( form ) {

                    console.log(form);

                    var valid = 0;

                    if(
                        ( $(form).attr("id") === "regist-step-2" || $(form).attr("id") === "form-agree-again" ) &&
                        !$("#agree-regist-terms").find(".j3w-checkbox").hasClass("checked")
                    ) {
                        $("#agree-regist-terms").addClass("error");
                        valid++;
                    }
                    else {
                        $("#agree-regist-terms").removeClass("error");
                    }

                    if ( valid === 0 ) {
                        form.submit();
                    }
                }
            });
            
        },
        
        setFormError: function( id, error ) {
            
            id = ( typeof id !== "undefined" ) ? id : 0;
            error = ( typeof error !== "undefined" ) ? error : '';
            
            var errorHTML = '<label id="'+ id +'" class="error">'+ error +'</label>';
            
            $('[name="'+ id +'"]').addClass("error").after( errorHTML );
            
        }
        
    };

    // Add core script to $.j3wTheme so it can be extended
    $.j3wTheme = J3WTheme.prototype;

    $(document).ready(function () {
        
        // Initialize script
        $j3wApp = new J3WTheme();

    });

})(jQuery);